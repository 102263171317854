import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ResourceBanner from '../components/ResourceBanner';
import NewsCards from '../components/NewsCards';

const NewsAndResourcesPage = ({
  data: {
    contentfulNewsResourcesArchive: { title, heading, featuredNewsResources },
    allContentfulNewsResource: { nodes },
    allContentfulNewsResourceCategory,
  },
}) => {
  return (
    <Layout>
      <main>
        <ResourceBanner title={title} items={featuredNewsResources} />
        <NewsCards
          heading={heading || title}
          categories={allContentfulNewsResourceCategory.nodes}
          items={nodes}
          isNewResourcesPage={true}
        />
      </main>
    </Layout>
  );
};

export const query = graphql`
  query NewsAndResourcesQuery {
    contentfulNewsResourcesArchive {
      title
      metaDescription
      featuredNewsResources {
        title
        image {
          gatsbyImageData(width: 1920, height: 834)
          title
        }
        newsResource {
          title
          slug
          pdf {
            title
            url
          }
          category {
            singularForm
          }
        }
      }
      slug
      heading
    }
    allContentfulNewsResource(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        date
        slug
        image {
          gatsbyImageData(width: 800, height: 528)
          title
        }
        category {
          title
          singularForm
          id
        }
        pdf {
          title
          url
        }
        excerpt
        content {
          raw
        }
        metaDescription
      }
    }
    allContentfulNewsResourceCategory {
      nodes {
        title
        singularForm
        id
      }
    }
  }
`;

export default NewsAndResourcesPage;

export const Head = ({
  data: {
    contentfulNewsResourcesArchive: { title, metaDescription },
  },
}) => {
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={metaDescription} />
    </>
  );
};
