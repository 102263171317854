import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { EffectFade, Pagination, Navigation } from 'swiper';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import styled from 'styled-components';
import {
  brandColours,
  brandFonts,
  breakpointSizes,
  fluidFontSize,
  fontSize,
  maxBreakpointQuery,
  minBreakpointQuery,
  standardColours,
  standardTransition,
} from '../styles';
import { Button, Container, Slider } from './ui';
import arrowLeft from '../images/arrow-left.svg';
import arrowRight from '../images/arrow-right.svg';
import Breadcrumbs from './Breadcrumbs';

const StyledResourceBanner = styled.div``;

const StyledContainer = styled(Container)`
  position: relative;
  padding: 0;
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  color: ${standardColours.white};
  margin-left: 30px;

  ${minBreakpointQuery.xlarge`
    margin-left: 54px;
  `}
`;

const StyledSlider = styled(Slider)`
  height: 100%;
  .swiper-button-prev,
  .swiper-button-next {
    border-radius: 50%;
    border: 2px solid ${standardColours.white};
    width: 72px;
    height: 72px;
    bottom: 10px;
    top: unset;
    left: unset;
    scale: 0.7;

    ${minBreakpointQuery.xlarge`
      bottom: 50px;
      scale: 1;
    `}

    &:hover {
      background: ${brandColours.primary};
      font-size: 40px;
    }
  }
  .swiper-button-next {
    right: 0;

    ${minBreakpointQuery.small`
      right: 10px;
    `}

    ${minBreakpointQuery.xlarge`
      right: 50px;
    `}

    &:after {
      content: unset;
    }

    &:before {
      content: '';
      background: url(${arrowRight}) no-repeat;
      height: 17px;
      width: 34px;
    }
  }
  .swiper-button-prev {
    left: 0;

    ${minBreakpointQuery.small`
      left: 10px;
    `}

    ${minBreakpointQuery.xlarge`
      left: 50px;
    `}

    &:after {
      content: unset;
    }
    &:before {
      content: '';
      background: url(${arrowLeft}) no-repeat;
      height: 17px;
      width: 34px;
    }
  }
  .swiper-button-disabled {
    opacity: 0.3;
  }

  .swiper-pagination {
    top: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: auto;
    padding-left: 15px;
    justify-content: center;

    ${minBreakpointQuery.small`
      padding-left: 30px;
    `}

    ${maxBreakpointQuery.xlarge`
      margin-bottom: 70px;
    `}
  }

  .swiper-pagination-bullet {
    opacity: 1;
    width: 32px;
    height: 32px;
    background-color: ${standardColours.transparent};
    &:before {
      content: '';
      font-size: 0px;
      padding: 6px;
      border-radius: 50%;
      background-color: ${standardColours.white};
    }
  }

  .swiper-pagination-bullet-active {
    &:before {
      background-color: ${brandColours.primary};
      padding: 17px 6px;
      border-radius: 14px;
      transition: ${standardTransition('padding')};
    }
  }
  .swiper-slide {
    height: auto;
  }
`;

const StyledSlide = styled.div`
  display: grid;
  height: 100%;
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: auto;
  grid-area: 1 / 1 / 1 / 1;
  min-height: 400px;
`;

const StyledContent = styled.div`
  display: flex;
  grid-area: 1 / 1 / 1 / 1;
  flex-direction: column;
  position: relative;
  height: 100%;
  justify-content: center;
  margin-left: 80px;
  gap: 16px;
  color: ${standardColours.white};
  padding: 30px 0;

  ${minBreakpointQuery.small`
    margin-left: 120px;
  `}

  ${minBreakpointQuery.large`
    gap: 32px;
    margin-left: 160px;
  `}
`;

const StyledCategory = styled.span`
  text-transform: uppercase;
  letter-spacing: 6px;
`;

const StyledHeading = styled.h2`
  font-family: ${brandFonts.tertiary};

  ${fluidFontSize(
    '20px',
    '52px',
    breakpointSizes.tiny,
    breakpointSizes.xxlarge
  )};

  max-width: 640px;
  margin-right: 30px;
`;

const StyledButton = styled(Button)`
  position: relative;
  display: flex;
  background-color: ${standardColours.transparent};
  color: ${standardColours.white};
  border: solid 2px ${standardColours.white};
  max-width: 220px;
  ${fontSize(18)}
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    background: url(${arrowRight}) no-repeat;
    height: 17px;
    width: 34px;
    display: block;
    right: 10px;

    ${minBreakpointQuery.medium`
      right: 30px;
    `}
  }

  ${minBreakpointQuery.medium`
    padding: 22px 30px;
    max-width: 282px;
  `}

  &:hover {
    &:after {
      right: 6px;
      transition: ${standardTransition('right')};

      ${minBreakpointQuery.medium`
        right: 26px;
        
      `}
    }
  }
`;

const StyledButtonText = styled.p`
  ${maxBreakpointQuery.medium`
   margin-right: 20px;
  `}
`;

const ResourceBanner = ({ title, items }) => {
  items && (items = items.slice(0, 3));
  const sliderOptions = {
    modules: [EffectFade, Pagination, Navigation],
    fadeEffect: { crossFade: true },
    slidesPerView: 1,
    effect: 'fade',
    pagination: { clickable: true },
    navigation: true,
  };
  return (
    <StyledResourceBanner>
      <StyledContainer wide={true}>
        <StyledBreadcrumbs currentPageTitle={title} />
        <StyledSlider {...sliderOptions}>
          {items.map(
            (
              {
                image,
                newsResource: {
                  title,
                  slug,
                  pdf,
                  category: { singularForm },
                },
              },
              id
            ) => (
              <StyledSlide key={id}>
                <StyledImage image={image.gatsbyImageData} alt={image.title} />
                <StyledContent>
                  <StyledCategory>{singularForm}</StyledCategory>
                  <StyledHeading>{title}</StyledHeading>
                  {pdf && (
                    <StyledButton
                      href={pdf.url}
                      target="_blank"
                      rel="noreferer"
                      alt={pdf.title}
                    >
                      <StyledButtonText>Download</StyledButtonText>
                    </StyledButton>
                  )}
                  {!pdf && (
                    <StyledButton href={slug}>
                      <StyledButtonText>Read More</StyledButtonText>
                    </StyledButton>
                  )}
                </StyledContent>
              </StyledSlide>
            )
          )}
        </StyledSlider>
      </StyledContainer>
    </StyledResourceBanner>
  );
};

export default ResourceBanner;
